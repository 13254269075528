<template>
  <div class="content">
    <div class="content__title content__title--party">
      <div class="content__title content__title--party content__title--start">
        <router-link
          :to="{ name: 'passport' }"
          class="button button--light button--no-indent button--small"
        >
          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z"
                  fill="#2B93E7"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z"
                  fill="#2B93E7"/>
          </svg>
        </router-link>
        <h1 class="title title--big title--theme">Общие сведения об организации</h1>
      </div>
      <button v-if="userRole === 'admin'" @click="openSidebar" class="link" type="button">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7 17.0134L11.413 16.9984L21.045 7.45839C21.423 7.08039 21.631 6.57839 21.631 6.04439C21.631 5.51039 21.423 5.00839 21.045 4.63039L19.459 3.04439C18.703 2.28839 17.384 2.29239 16.634 3.04139L7 12.5834V17.0134ZM18.045 4.45839L19.634 6.04139L18.037 7.62339L16.451 6.03839L18.045 4.45839ZM9 13.4174L15.03 7.44439L16.616 9.03039L10.587 15.0014L9 15.0064V13.4174Z"
            fill="#2B93E7"/>
          <path
            d="M5 21H19C20.103 21 21 20.103 21 19V10.332L19 12.332V19H8.158C8.132 19 8.105 19.01 8.079 19.01C8.046 19.01 8.013 19.001 7.979 19H5V5H11.847L13.847 3H5C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21Z"
            fill="#2B93E7"/>
        </svg>
        <span>Управление</span>
      </button>
    </div>
    <div v-if="userRole === 'admin' && manage.comment_tech" class="comment">
      <div class="comment__info">
        {{ manage.comment_tech }}
      </div>
    </div>
    <form class="form" @submit.prevent="onSendForm">
      <div class="form__content">
        <div class="form__part">
          <div class="form-group">
            <label>Юридическое лицо</label>
            <v-select
              @input="setValues"
              v-model="org_detail"
              :filterable="false"
              :options="organization_options"
              @search="onOrgSearch"
              :get-option-label="getLabel"
              placeholder="Введите ИНН или название организации"
              class="select"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template slot="no-options">Введите ИНН или название организации</template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">{{ option.name }} ({{ option.inn }}) ({{ option.address }})</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
            </v-select>
          </div>
          <div class="form-group">
            <label>Осуществляет деятельность на основании</label>
            <div class="form-group__party">
              <label class="radio">
                <div class="radio__text">Федерального Устава РСМ</div>
                <input type="radio" name="is_rsm_statute" v-model="form.is_rsm_statute" :value="true" />
                <div class="radio__radiomark"></div>
              </label>
              <label class="radio">
                <div class="radio__text">Регионального Устава</div>
                <input type="radio" name="is_rsm_statute" v-model="form.is_rsm_statute" :value="false" />
                <div class="radio__radiomark"></div>
              </label>
            </div>
          </div>
          <div v-if="!form.is_rsm_statute" class="form-group">
            <label>Действующий устав организации</label>
            <FakeUploader v-if="form.statute_url" @deleteFakePassport="onRemoveFile('statute_url')" :photoUrl="form.statute_url" />
            <uploader v-else :getFileHash="getFileHash" limit-files="1" :fileUrl="file_url" @remove-file="onRemoveFile('statute')"/>
          </div>
          <div v-if="!form.is_rsm_statute" class="form-group">
            <label for="date_statute">Дата регистрации действующей редакции Устава организации</label>
            <div class="form-control">
              <masked-input
                v-model="form.date_statute"
                name="date_statute"
                mask="11.11.1111"
                placeholder="дд.мм.гггг"
                class="form-control__input"
                id="date_statute"
                autocomplete="off"
              />
            </div>
          </div>
          <div v-if="!form.is_rsm_statute" class="form-group">
  <label for="last_change_date">Дата внесения изменений в действующую редакцию Устава организации</label>
  <div class="form-control">
    <masked-input
      v-model="form.last_change_date"
      name="last_change_date"
      mask="11.11.1111"
      placeholder="дд.мм.гггг"
      class="form-control__input"
      id="last_change_date"
      autocomplete="off"
    />
  </div>
</div>
          <div class="content__title">
            <h2 class="title">Реквизиты свидетельства о государственной регистрации НКО, выданного органом юстиции</h2>
          </div>
          <div class="form-group">
            <label for="cert_num">Номер свидетельства</label>
            <div class="form-control">
              <input
                id="cert_num"
                type="number"
                min="0"
                class="form-control__input"
                name="cert_num"
                placeholder="Введите номер свидетельства"
                v-model.trim="form.cert_num"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="cert_date">Дата выдачи</label>
            <div class="form-control">
              <masked-input
                v-model="form.cert_date"
                name="date_statute"
                mask="11.11.1111"
                placeholder="дд.мм.гггг"
                class="form-control__input"
                id="cert_date"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="cert_whom">Кем выдано</label>
            <div class="form-control">
              <textarea
                id="cert_whom"
                name="cert_whom"
                class="form-control__textarea"
                v-model.trim="form.cert_whom"
                placeholder="Введите орган выдавший свидетельство"
              >
              </textarea>
            </div>
          </div>
          <div class="form-group">
            <label>Адрес места нахождения (по ЕГРЮЛ)</label>
            <v-select
              @input="setAddressValues"
              v-model="address_detail"
              :filterable="false"
              :options="address_options"
              @search="onAddressSearch"
              :get-option-label="getLabel"
              placeholder="Введите адрес места нахождения"
              class="select"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template slot="no-options">Введите адрес места нахождения</template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">{{ option.address }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.address }}</div>
              </template>
            </v-select>
          </div>
          <div class="form-group">
            <label>Фактический адрес</label>
            <v-select
              @input="setFactAddressValues"
              v-model="fact_address_detail"
              :filterable="false"
              :options="address_options"
              @search="onAddressSearch"
              :get-option-label="getLabel"
              placeholder="Введите фактический адрес"
              class="select"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template slot="no-options">Введите фактический адрес</template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">{{ option.address }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.address }}</div>
              </template>
            </v-select>
          </div>
          <div class="form-group">
            <label>Почтовый адрес</label>
            <v-select
              @input="setPostAddressValues"
              v-model="post_address_detail"
              :filterable="false"
              :options="address_options"
              @search="onAddressSearch"
              :get-option-label="getLabel"
              placeholder="Введите почтовый адрес"
              class="select"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template slot="no-options">Введите почтовый адрес</template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">{{ option.address }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.address }}</div>
              </template>
            </v-select>
          </div>
          <div class="form-group">
            <label>Телефон организации</label>
            <vue-tel-input
              v-model="phone_value"
              defaultCountry="RU"
              mode="international"
              type="tel"
              class="phonepicker"
              :class="{ 'phonepicker--error': !is_phone_valid }"
              @validate="onValidatePhone"
              :inputOptions="phone_picker_options"
              validCharactersOnly
            >
              <template slot="arrow-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" fill="none">
                  <path d="M4.96 6.75L.23 1.47a.93.93 0 010-1.22.71.71 0 011.09 0L5.5 4.92 9.68.25a.71.71 0 011.1 0c.3.34.3.88 0 1.22L6.03 6.75A.73.73 0 015.5 7c-.2 0-.4-.08-.54-.25z" fill="#2B93E7"></path>
                </svg>
              </template>
            </vue-tel-input>
            <div class="form-group__error" v-if="!is_phone_valid">Недопустимое значение для номера телефона</div>
          </div>
          <div class="form-group">
            <label for="email">Электронная почта организации</label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.email.$error }">
              <input
                id="email"
                type="email"
                name="email"
                class="form-control__input"
                placeholder="Введите электронную почту"
                v-model.trim="form.email"
                @change="$v.form.email.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.email.$dirty && !$v.form.email.email">
              Введите правильный формат почты
            </div>
          </div>
        </div>
        <div class="form__part">
          <div class="content__title">
            <h2 class="title">Банковские реквизиты</h2>
          </div>
          <div class="form-group">
            <label>БИК</label>
            <v-select
              @input="setBikValues"
              v-model="bik_detail"
              :filterable="false"
              :options="bik_options"
              @search="onBikSearch"
              :get-option-label="getLabel"
              placeholder="Введите БИК или название организации"
              class="select"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                        stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </svg>
              </template>
              <template slot="no-options">Введите БИК или название организации</template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">{{ option.name }} ({{ option.bic }})</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.name }} ({{ option.bic }})</div>
              </template>
            </v-select>
          </div>
          <div class="form-group">
            <label for="bank">Название банка</label>
            <div class="form-control">
              <input
                id="bank"
                type="text"
                class="form-control__input"
                name="bank"
                v-model.trim="form.bank"
                readonly
              />
            </div>
          </div>
          <div class="form-group">
            <label for="account_cor">Корреспондентский счёт</label>
            <div class="form-control">
              <input
                id="account_cor"
                type="text"
                class="form-control__input"
                name="account_cor"
                v-model.trim="form.account_cor"
              />
            </div>
          </div>
          <div class="form-group">
            <label>Тип счёта</label>
            <div class="form-group__party">
              <label class="radio">
                <div class="radio__text">Расчётный счёт</div>
                <input type="radio" name="account_type" v-model.number="form.account_type" value="1" />
                <div class="radio__radiomark"></div>
              </label>
              <label class="radio">
                <div class="radio__text">Лицевой счёт</div>
                <input type="radio" name="account_type" v-model.number="form.account_type" value="2" />
                <div class="radio__radiomark"></div>
              </label>
            </div>
          </div>
          <div class="form-group">
            <label for="account_num">Номер счета</label>
            <div class="form-control">
              <input
                id="account_num"
                type="text"
                class="form-control__input"
                name="account_num"
                v-model.trim="form.account_num"
              />
            </div>
          </div>
          <div class="content__title">
            <h2 class="title">Материально-технические ресурсы, имеющиеся в распоряжении региональной организации РСМ</h2>
          </div>
          <div class="form-group">
            <label for="premises">Помещения</label>
            <div class="form-control">
              <textarea
                id="premises"
                name="premises"
                class="form-control__textarea"
                v-model.trim="form.premises"
                placeholder="Введите помещения"
              >
              </textarea>
            </div>
          </div>
          <div class="form-group">
            <label for="equipment">Техника и оборудование</label>
            <div class="form-control">
              <textarea
                id="equipment"
                name="equipment"
                class="form-control__textarea"
                v-model.trim="form.equipment"
                placeholder="Введите технику и оборудование"
              >
              </textarea>
            </div>
          </div>
          <div class="form-group">
            <label>Наличие попечительского совета</label>
            <div class="form-group__party">
              <label class="radio">
                <div class="radio__text">Да</div>
                <input type="radio" name="has_bt" v-model="form.has_bt" :value="true" />
                <div class="radio__radiomark"></div>
              </label>
              <label class="radio">
                <div class="radio__text">Нет</div>
                <input type="radio" name="has_bt" v-model="form.has_bt" :value="false" />
                <div class="radio__radiomark"></div>
              </label>
            </div>
          </div>
          <div v-if="form.has_bt" class="form-group">
            <label>Положение о попечительском совете</label>
            <FakeUploader v-if="form.position_url" @deleteFakePassport="onRemoveFile('position_url')" :photoUrl="form.position_url" />
            <uploader v-else :getFileHash="getFilePositionHash" limit-files="1" :fileUrl="file_url" @remove-file="onRemoveFile('position')"/>
          </div>
          <div v-if="form.has_bt" class="form-group">
            <label>Персональный состав попечительского совета</label>
            <FakeUploader v-if="form.personal_composition_url" @deleteFakePassport="onRemoveFile('personal_composition_url')" :photoUrl="form.personal_composition_url" />
            <uploader v-else :getFileHash="getFileCompositionHash" limit-files="1" :fileUrl="file_url" @remove-file="onRemoveFile('personal_composition')"/>
          </div>
        </div>
      </div>
      <div class="holder holder--end">
        <button class="button" type="submit" :class="{ 'preload': loading }">
          <span>Сохранить</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
      </div>
    </form>
    <SidebarManage
      :title="'Управление'"
      :class="{'sidebar-manage--full': show_sidebar}"
      @close-sidebar="show_sidebar = !show_sidebar"
    >
      <form>
        <div class="form-group">
          <label for="comment_tech">Служебный комментарий</label>
          <div class="form-control">
            <textarea
              @change="sendManage"
              id="comment_tech"
              name="comment_tech"
              class="form-control__textarea"
              v-model.trim="manage.comment_tech"
              placeholder="Видят только администраторы"
            >
            </textarea>
          </div>
        </div>
      </form>
      <div class="sidebar-card">
        <div class="sidebar-card__item sidebar-card__item--between">
          <b>Дата создания</b> <span>{{ form.created_at }}</span>
        </div>
        <div class="sidebar-card__item sidebar-card__item--between">
          <b>Кем создано</b> <span>{{ form.created_name }}</span>
        </div>
        <div class="sidebar-card__item sidebar-card__item--between">
          <b>Дата обновления</b> <span>{{ form.updated_at }}</span>
        </div>
        <div class="sidebar-card__item sidebar-card__item--between">
          <b>Кем обновлено</b> <span>{{ form.updated_name || '-' }}</span>
        </div>
      </div>
    </SidebarManage>
  </div>
</template>

<script>
import { debounce, omit } from 'lodash'
import { validationMixin } from 'vuelidate'
import { email } from 'vuelidate/lib/validators'
import SidebarManage from '@/components/SidebarManage'
import Uploader from '@/components/Uploader'
import FakeUploader from '@/components/FakeUploader'
import MaskedInput from 'vue-masked-input'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

export default {
  name: 'PassportOrganization',
  mixins: [ validationMixin ],
  components: {
    SidebarManage,
    Uploader,
    MaskedInput,
    VueTelInput,
    FakeUploader,
  },
  validations: {
    form: {
      email: { email },
    }
  },
  data() {
    return {
      form: {
        is_rsm_statute: true,
        account_type: 1,
        has_bt: false,
      },
      manage: {},
      send_post: false,
      org_detail: null,
      address_detail: null,
      fact_address_detail: null,
      post_address_detail: null,
      bik_detail: null,
      loading: false,
      show_sidebar: false,
      status_options: [],
      organization_options: [],
      address_options: [],
      bik_options: [],
      file_url: `${process.env.VUE_APP_API_URL}files/`,
      phone_value: '',
      is_phone_valid: true,
      phone_picker_options: {
        placeholder: 'Введите номер телефона'
      },
    }
  },
  computed: {
    userRegion() {
      return this.$store.state.organization
    },
    userRole() {
      return this.$store.state.userRole
    }
  },
  mounted() {
    this.fetchForm()
    if (this.userRegion) this.form.region_org = this.userRegion
  },
  methods: {
    fetchForm () {
      this.$store.dispatch('passport/GET_ORG_DATA')
        .then(response => {
          this.form = {...this.form, ...response.data}
          this.phone_value = response.data.phone ?? ''
          let { comment_tech } = response.data
          this.manage = { comment_tech }
          this.org_detail = response.data.entity_json
          if (response.data.address_usrle) this.address_detail = { address: response.data.address_usrle }
          this.post_address_detail = response.data.address_mail_json
          this.fact_address_detail = response.data.address_json
          if (response.data.bik) {
            this.$store.dispatch('FIND_BIK', { search: response.data.bik })
              .then(response => {this.bik_detail = response.data})
          }
        })
        .catch(() => {
          this.send_post = true
        })
    },
    onSendForm() {
      if (!this.form.cert_date) {
        this.form = omit(this.form, ['cert_date'])
      }
      if (!this.form.date_statute) {
        this.form = omit(this.form, ['date_statute'])
      }
      if (this.form.is_rsm_statute) {
        this.form.statute = null
      }
      if (this.send_post) {
        this.loading = true
        this.$store.dispatch('passport/POST_ORG_DATA', this.form)
          .then(() => {
            this.loading = false
            this.$router.push({ name: 'passport' })
            this.$notify({
              type: 'success',
              title: 'Успех!',
              text: 'Изменения сохранены.'
            })
          })
          .catch(() => {
            this.loading = false
            this.$notify({
              type: 'error',
              title: 'Ошибка!',
              text: 'Ошибка сохранения изменений.'
            })
          })
      } else {
        this.loading = true
        this.$store.dispatch('passport/PATCH_ORG_DATA', this.form)
          .then(() => {
            this.loading = false
            this.$router.push({ name: 'passport' })
            this.$notify({
              type: 'success',
              title: 'Успех!',
              text: 'Изменения сохранены.'
            })
          })
          .catch(() => {
            this.loading = false
            this.$notify({
              type: 'error',
              title: 'Ошибка!',
              text: 'Ошибка сохранения изменений.'
            })
          })
      }
    },
    sendManage() {
      this.form = {...this.form, ...this.manage}
      this.$store.dispatch('passport/PATCH_ORG_DATA', this.manage)
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Изменения сохранены.'
          })
        })
        .catch(() => {
          this.loading = false
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка сохранения данных.'
          })
        })
    },
    setValues() {
      if (this.org_detail) {
        this.form.entity_json = this.org_detail
        this.form.entity = this.org_detail.name
        this.address_detail = { address: this.org_detail.address }
        this.form.address_usrle = this.org_detail.address
      } else {
        this.form.entity_json = ''
        this.form.entity = ''
      }
    },
    setAddressValues() {
      if (this.address_detail) {
        this.form.address_usrle = this.address_detail.address
      } else {
        this.form.address_usrle = ''
      }
    },
    setFactAddressValues() {
      if (this.fact_address_detail) {
        this.form.address_json = this.fact_address_detail
        this.form.address = this.fact_address_detail.address
      } else {
        this.form.address_json = ''
        this.form.address = ''
      }
    },
    setPostAddressValues() {
      if (this.post_address_detail) {
        this.form.address_mail_json = this.post_address_detail
        this.form.address_mail = this.post_address_detail.address
      } else {
        this.form.address_mail_json = ''
        this.form.address_mail = ''
      }
    },
    setBikValues() {
      if (this.bik_options) {
        this.form.bik = this.bik_detail.bic
        this.form.bank = this.bik_detail.name
        this.form.account_cor = this.bik_detail.cor_account
      }
    },
    getFileHash(value) {
      this.form.statute = value
    },
    getFilePositionHash(value) {
      this.form.position = value
    },
    getFileCompositionHash(value) {
      this.form.personal_composition = value
    },
    onRemoveFile(name) {
      this.form[name] = ''
    },
    onAddressSearch (search, loading) {
      loading(true)
      this.searchAddress(loading, search, this)
    },
    searchAddress: debounce((loading, search, vm) => {
      vm.$store.dispatch('FIND_ADDRESS', { search }).then(response => {
        vm.address_options = response.data
        loading(false)
      })
    }, 350),
    onOrgSearch (search, loading) {
      loading(true)
      this.searchOrg(loading, search, this)
    },
    searchOrg: debounce((loading, search, vm) => {
      vm.$store.dispatch('FIND_ORGANIZATION', { search }).then(response => {
        vm.organization_options = response.data
        loading(false)
      })
    }, 350),
    onBikSearch(search, loading) {
      loading(true)
      this.searchBik(loading, search, this)
    },
    searchBik: debounce((loading, search, vm) => {
      vm.$store.dispatch('FIND_BIK', { search }).then(response => {
        vm.bik_options = response.data
        loading(false)
      })
    }, 350),
    getLabel (option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          )
        }
        return option[this.label]
      }
      return option
    },
    onValidatePhone(number) {
      this.is_phone_valid = number.valid
      if (number.valid && number.number !== '') {
        this.form.phone = number.number
      } else {
        this.form.phone = ''
      }
      if (!number.hasOwnProperty('number')) {
        this.is_phone_valid = true
      }
    },
    openSidebar() {
      this.show_sidebar = true
    }
  }
}
</script>
